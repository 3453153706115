export const container = {
  p: '10px 0 0 10px',
  '@media (max-width: 960px)': {
    p: '5px 0 0 5px',
  },
  '@media (max-width: 425px)': {
    p: '5px 0 0 0px',
  },
  alignItems: 'center',
};
export const grid = { mt: 0.5, mb: 1 };
export const spacingStyle = {
  '@media (max-width: 425px)': {
    p: '0 0 0 0',
  },
};
