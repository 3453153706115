import { Grid } from '@mui/material';
import React from 'react';
import { Category } from 'services/interfaces/Category';
import MainPageCard from '../MainPageCard';
import { container, grid, spacingStyle } from '../styles/mainPageStyles';
import { Company } from 'services/interfaces/Company';
import { Searchbar } from 'features/Searchbar';

const MainPage = ({
  categories,
  company,
}: {
  categories: Category[];
  company: Company[] | [];
}) => {
  return (
    <Grid container sx={container}>
      <Grid item xs={12} sx={grid}>
        <Grid container spacing={1} sx={spacingStyle}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ mt: 0, mb: 2, flexBasis: '100%' }}
          >
            <Searchbar width="100%" pl={0} pr={0} slice={8} />
          </Grid>
          {categories.map((category: Category) => (
            <MainPageCard
              key={category.id}
              category={category}
              company={company}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainPage;
