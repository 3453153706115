export const declination = (
  total: any,
  single: string,
  multiple1: string,
  multiple2: string
) => {
  const lastDigit = total % 10;
  const penultimateDigit = Math.floor(total / 10) % 10;

  if (penultimateDigit === 1) {
    return multiple2;
  }

  if (lastDigit === 1) {
    return single;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return multiple1;
  } else {
    return multiple2;
  }
};
